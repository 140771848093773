@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
  Note: The styles for this gradient grid background is heavily inspired by the creator of this amazing site (https://dub.sh) – all credits go to them! 
*/

.main {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  padding: 120px 24px 160px 24px;
  pointer-events: none;
  background: black;
}


.main:before {

  position: absolute;
  content: "";
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
}

.main:after {
  content: "";
  background-image: url("/src/assets/grid.svg");
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.1;
  filter: invert(1);
}
.about-container {
  background-color: black;
  color: white; /* Ensures text is readable against the black background */
  padding: 20px; /* Optional: Adds some padding for better layout */
}
.zIndex5 {
  z-index: 5;
}



.gradient {
  pointer-events: none; 
  height: fit-content;
  z-index: 3;
  width: 100%;
  background-image: radial-gradient(
      at 27% 37%,
      hsla(215, 98%, 61%, 1) 0px,
      transparent 0%
    ),
    radial-gradient(at 0% 50%, #17dd31 0px, transparent 50%),
    radial-gradient(at 0% 25%, rgb(7, 83, 204) 0px, transparent 50%),
    radial-gradient(at 0% 75%, rgb(13, 72, 219) 0px, transparent 50%),
    radial-gradient(at 50% 25%, hsla(38, 60%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 50% 50%, hsla(222, 67%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 50% 75%, rgb(230, 23, 161) 0px, transparent 50%),  

    radial-gradient(at 100% 30%, hsla(222, 67%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 100% 60%, rgb(230, 23, 161) 0px, transparent 50%);

  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  filter: blur(100px) saturate(150%);
  top: 20px;
  opacity: 0.2;
}

@media screen and (max-width: 640px) {
  .main {
    padding: 0;
  }
}


@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 2s fadeInUp;
}

.fadeInUp-animation2 {
  animation: 6s fadeInUp;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Tailwind Styles */

.app {
  @apply bg-black relative z-10 flex justify-center items-center flex-col max-w-7xl mx-auto sm:px-16 px-6;
}

.all {
  @apply bg-black;
}
.app_twocolumn {
  @apply relative z-10 flex flex-col max-w-7xl mx-auto sm:px-20 px-20 columns-2;
}


.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.footer {
  @apply fixed bottom-4 left-0 right-0 z-10 flex justify-center items-center flex-col max-w-7xl mx-auto sm:px-16 px-6;
}
.flex-container {
  display: flex; /* Use flexbox to align items side by side */
  align-items: center; /* Vertically center the items */
}

.black_btn {
  @apply rounded-full border border-black bg-white py-1.5 px-5 text-sm text-black transition-all;
}

.head_text {
  @apply mt-5 text-5xl font-extrabold leading-[1.15] text-black sm:text-6xl text-center;
}

.orange_gradient {
  @apply bg-gradient-to-r from-amber-500 via-orange-600 to-yellow-500 bg-clip-text text-transparent;
}

.purple_gradient {
  @apply font-black bg-gradient-to-r from-purple-600 to-purple-800 bg-clip-text text-transparent;
}

.white_gradient {
  @apply font-black bg-gradient-to-r from-purple-100 to-purple-200 bg-clip-text text-transparent;
}


.desc_0 {
  @apply mt-5 text-sm text-white sm:text-sm text-center max-w-2xl;
}

.desc {
  @apply mt-5 text-lg text-white sm:text-xl text-center max-w-2xl;
}


.desc_2 {
  @apply mt-5 text-2xl text-white sm:text-2xl text-center max-w-3xl;
}

.sub_main {
  @apply mt-5 text-2xl text-white sm:text-2xl text-center max-w-2xl ;
}

.descFooter {
  @apply mt-5 text-gray-400 text-center max-w-2xl;
  font-size: 0.65rem; /* 10px */
}


.url_input {
  @apply block w-full rounded-md border border-gray-200 bg-white py-2.5 pl-10 pr-12 text-sm shadow-lg font-medium focus:border-black focus:outline-none focus:ring-0;
}

.submit_btn {
  @apply hover:border-gray-700 hover:text-gray-700 absolute inset-y-0 right-0 my-1.5 mr-1.5 flex w-10 items-center justify-center rounded border border-gray-200 font-sans text-sm font-medium text-gray-400;
}

.link_card {
  @apply p-3 flex justify-start items-center flex-row bg-white border border-gray-200 gap-3 rounded-lg cursor-pointer;
}

.copy_btn {
  @apply w-7 h-7 rounded-full bg-white/10 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur flex justify-center items-center cursor-pointer;
}

.blue_gradient {
  @apply font-black bg-gradient-to-r from-blue-600 to-cyan-600 bg-clip-text text-transparent;
}

.summary_box {
  @apply rounded-xl border border-gray-200 bg-white/20 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur p-4;
}

.header {
  background-color: black;
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

}

.nav_links {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.nav_link {
  color: white;
  text-decoration: none;
}

.nav_link:hover {
  text-decoration: underline;
}